import React, { useEffect } from 'react';
import { Container, Grid, Space } from '@mantine/core';
import Frame from '@unserkunde/enscompare-components/src/components/layout/Frame';
import { Button } from '@unserkunde/enscompare-components/src/components/components';
import { FaArrowRight } from 'react-icons/fa';
import { useAppDispatch, useAppSelector, useDynamicFields } from '../../hooks';
import { navigateTo, submitOfferRequestProcess } from '../../actions/progress';
import { InitilizeFilterSettings } from './OfferRequestProcessFilterSettings';
import OfferReqestProcessInputs from './OfferReqestProcessInputs';
import { useDefaultSettings } from './DefaultSettingsLoader';
import DynamicEntryQuestion from 'forms/shared/DynamicEntryQuestion';
import { useOfferRequestSettings } from 'processes/offerRequestProcess/IsOfferRequestProcess';

function ButtomButtonRow() {
  const dispatch = useAppDispatch();
  const onSubmit = () => dispatch(submitOfferRequestProcess());
  const onGotoSettings = () => dispatch(navigateTo('offerRequestProcessSettings'));

  const offerRequestSettings = useOfferRequestSettings();

  return (
    <Container px={0}>
      <Grid>
        {offerRequestSettings.offerRequestNoSettings ? null : (
          <Grid.Col
            xs={12}
            sm={6}>
            <Button
              fullWidth
              variant='outline'
              onClick={onGotoSettings}>
              Einstellungen
            </Button>
          </Grid.Col>
        )}
        <Grid.Col
          xs={12}
          sm={6}>
          <Button
            color='secondary'
            fullWidth
            onClick={onSubmit}>
            Angebote anfordern&nbsp;&nbsp;
            <FaArrowRight color='white' />
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export const OfferRequestProcess = () => {
  const entryQuestions = useDynamicFields(null, (o) => o.displayPositions?.includes('offerrequestprocess'));
  const defaultBikeId = useAppSelector((state) => state.userData.defaultBikeId);

  const dispatch = useAppDispatch();

  useDefaultSettings();

  useEffect(() => {
    InitilizeFilterSettings(dispatch);
  }, []);

  return (
    <>
      <Container>
        <Frame px={0}>
          <h2>Versicherungsangebot anfordern</h2>

          <OfferReqestProcessInputs />

          {entryQuestions.map((e, i) => (
            <DynamicEntryQuestion
              bikeId={e.positionType === 'object' ? defaultBikeId : null}
              setting={e}
              key={i}
            />
          ))}
        </Frame>
        <ButtomButtonRow />
        <Space h='lg' />
      </Container>
    </>
  );
};

export default OfferRequestProcess;
