import { useEffect } from 'react';
import { useAppSelector } from '../../hooks';
import {
  GetOfferRequestProcessSetting,
  OfferRequestProcessSettingNames,
  SetOfferRequestProcessSetting
} from './OfferRequestProcessSettings';

export const useDefaultSettings = () => {
  const filters = useAppSelector((state) => state.customFilter.filter);

  const instanceMode = useAppSelector((state) => state.ensfields.ens_instance_mode);

  useEffect(() => {
    if (!filters || filters.length === 0) return;

    const settingsLoadedKey = 'ens_defaults_loaded_v1';
    if (!GetOfferRequestProcessSetting(settingsLoadedKey)) {
      SetOfferRequestProcessSetting(OfferRequestProcessSettingNames.useRecommendedFilter, '1');

      console.log('IM', instanceMode);

      if (instanceMode === 'bike') {
        SetOfferRequestProcessSetting(
          OfferRequestProcessSettingNames.filterValues,
          JSON.stringify({
            withDiebstahlschutz: true,
            withSchutzbrief: true
          })
        );
      }

      SetOfferRequestProcessSetting(settingsLoadedKey, '1');
    }
  }, []);
};
