import { ReactElement, useMemo } from 'react';
import { EnsFieldsReducer } from '@/reducer/ensfields';
import { useAppSelector } from '@/hooks';

export type EnsFieldsSwitchProps<T extends keyof EnsFieldsReducer> = {
  ensField: T;
  children: ReactElement<any, any>;
  check: (value: EnsFieldsReducer[T]) => boolean;
};

function EnsFieldsSwitch<T extends keyof EnsFieldsReducer>({
  ensField,
  children,
  check
}: EnsFieldsSwitchProps<T>): ReactElement<any, any> {
  const ensFieldValue = useAppSelector((state) => state.ensfields[ensField]);
  const display = useMemo(() => check(ensFieldValue), [ensFieldValue]);

  if (!display) return null;

  return children;
}

export default EnsFieldsSwitch;
